<template>
 <div class="share-nodata" >
    <div class="share-nodata-content">
      <img v-if="type === 'expire'" src="@/assets/nodata/share_nodata.png" alt="">
      <img v-else-if="type === 'upperlimit'" src="@/assets/nodata/share_maxnum.png" alt="">
      <p class="color_26"> <i class="icon-upgrade-remind"></i> {{msg}}</p>
    </div>
  </div>
</template>

<script>
const allType = {
    1:"error",
    2:"inexistence",
    3: "expire",
    4: "upperlimit"
};
export default {
  data() {
    return {
      type: "",
      msg: ""
    };
  },
  mounted() {
    let { type, msg } = this.$route.params;
    this.msg = msg;
    this.type = type;
  },
}
</script>

<style lang="less">
.share-nodata{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 99999;
  &-content{
    width: 413px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    img{
      width: 100%;

    }
    p{
      font-size: 14px;
      color: #262626;
      line-height: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: -24px;
      i{margin-right: 5px;}
    }
  }
}
</style>